// Reset
@import "base/reset";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Fortawesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";

// Variables
@import "variables";

// Fonts
@import "base/typography";

// Components
@import "components/buttons";
@import "components/cards";
@import "components/modals";
@import "components/carousels";

.navbar-laravel {
    background-color: $body-bg;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.valor-etico-logo {
    height: 30px;
}

.div-information {
    flex-wrap: wrap;
    align-content: space-between;
    height: calc(100vh - 100px);
    width: 100%;
    margin: 0;
    .container {
        text-align: center;
    }
}

.fa-caret-square-down {
    cursor: pointer;
}

.bg-alumno,
.bg-color-1 {
    background-color: $employees;
}
.bg-denuncia {
    background-color: $complaint;
}
.bg-buenas-practicas {
    background-color: $good-practices;
}
.bg-proveedores,
.bg-color-2 {
    background-color: $providers;
}
.bg-stakeholders,
.bg-color-3 {
    background-color: $stakeholders;
}

.btnhomenb {
    height: 37px;
    border: 0px;
    background-color: transparent;
    border-radius: 5px;
    &:hover {
        cursor: pointer;
        background-color: #e9ecef;
    }
}
.div-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.footer-page {
    position: relative;
    bottom: 0;
    left: 0;
    color: $text-color-white;
    text-align: center;
    width: 100%;
    padding-top: 10px;
    p {
        color: $text-color-white;
        font-size: 0.8rem;
    }
}
.copyright {
    background-color: $body-bg;
    color: $text-color-default;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}
.fa-1-5x {
    font-size: 1.5em;
}

.login {
    max-width: 100%;
    height: auto;
    a {
        color: $link;
        font-size: 0.8rem;
    }
}
.text-action {
    color: white;
    text-align: left;
    font-weight: 300;
    font-size: 1.5em;
    letter-spacing: 2px;
    line-height: initial;
}
.text-action-buenas-practicas {
    color: white;
    text-align: left;
    font-weight: 300;
    font-size: 1em;
    letter-spacing: 2px;
    line-height: initial;
}

.denuncia-ahora {
    width: 100%;
    bottom: 0px;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-top: 20px;
    padding-bottom: 0;
    background-image: url("background_1.png");
}
.detalle-denuncia {
    div {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 3px;
    }
    .bg-light {
        border-radius: 5px;
    }
}
.buenas-practicas {
    border: none;
    margin-top: 20px;
    margin-bottom: 10px;
    img {
        border-radius: 5px;
    }
    .card {
        &-body {
            margin-top: 3px;
            padding-top: 1rem;
            border: 1px solid $good-practices;
            border-radius: 5px;
        }
        &-text {
            color: black;
            font-weight: 300;
            font-size: 0.9em;
            line-height: 1.2;
        }
    }
}
.ver-mas {
    p {
        color: $good-practices;
    }
    &:hover {
        color: $good-practices;
    }
}
.cls-1:hover,
.cls-2:hover {
    fill: red !important;
}
.nosub {
    text-decoration: none !important;
}
select#select {
    width: 150px;
    display: inline-block;
}
.dataTables_filter {
    input {
        width: 150px !important;
        display: inline-block;
    }
}
/* complaint form styles start */
.div-complaint {
    p {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }
    .stepwizard {
        display: table;
        width: 100%;
        position: relative;
        &-step p {
            margin-top: 0px;
            color: #666;
        }
        &-row {
            display: flex;
        }
        & .btn.disabled,
        & .btn[disabled],
        & fieldset[disabled] .btn {
            opacity: 1 !important;
            color: #bbb;
        }
        &-row:before {
            top: 25px;
            bottom: 0;
            position: absolute;
            content: " ";
            width: 100%;
            height: 1px;
            background-color: #ccc;
            z-index: 0;
        }
        &-step {
            display: table-cell;
            text-align: center;
            position: relative;
        }
    }
    .btn-circle {
        width: 30px;
        height: 30px;
        text-align: center;
        padding: 6px 0;
        font-size: 12px;
        line-height: 1.428571429;
        border-radius: 15px;
    }
    .div-form-detail {
        display: flex;
        flex-wrap: wrap;
        margin: 0.5rem -15px;
        text-align: left;
    }
}
/* complaint form styles end */

.inactive {
    background-color: $inactive;
    border: 0px;
}

.actores {
    margin-bottom: 100px;
    min-height: 55vh;
}
.txt-buenas-practicas {
    margin-top: 100px;
    margin-bottom: 100px;
}

.backcoloroff {
    background-color: antiquewhite;
}

.div-dashboard {
    margin-bottom: 100px;
    min-height: 80vh;
    margin-bottom: 0;
    background-color: #f8f9fa;
}

#divspinner {
    display: none;
    float: right;
}

.alert-danger {
    padding: 0.5rem 2rem;
    font-size: 0.8rem;
}
.modal-data-space {
    background-color: #f8f9fa;
    padding: 0.5rem;
    width: 300px;
    max-width: 500px;
    border: 2px solid white;
}
.modal-textarea {
    overflow-y: auto;
    height: 100px;
    width: auto;
    display: flex;
    flex-direction: column;
}

/* MEDIA MEDIA MEDIA MEDIA MEDIA MEDIA MEDIA MEDIA */
#comment {
    small {
        font-size: 70%;
    }
}
.div-filter {
    button {
        width: 100%;
    }
}
.fa-edit:hover,
.fa-trash:hover {
    cursor: pointer;
}
.email-reset-card {
    margin-top: 2rem;
    max-width: 100%;
    height: auto;
}
.change-password {
    width: 100%;
    height: 100%;
    max-width: 800px;
}
#consentModal {
    .modal-dialog {
        min-width: 50vw;
        @media (max-width: 767px) {
            max-width: 90vw;
        }

        ol {
            counter-reset: item;
            margin-left: 0;
            padding-left: 0;
        }
        li {
            display: block;
            margin-bottom: 0.5em;
            margin-left: 2em;
        }
        li::before {
            display: inline-block;
            content: "(" counter(item, lower-roman) ") ";
            counter-increment: item;
            width: 2em;
            margin-left: -2em;
        }
    }
}
.el {
    &-select-dropdown {
        max-width: 40%;
        &__item {
            overflow: visible;
        }
    }
    &-checkbox {
        margin-bottom: 0 !important;
    }
    &-message-box {
        width: 100% !important;
        max-width: 50vw;
        padding: 1rem;
        @media (max-width: 375px) {
            max-width: 90vw;
        }
        &__content {
            overflow-y: auto;
            height: 70vh;
        }
        ol {
            margin-left: 1rem;
        }
    }
    &-form-item {
        display: flex;
        align-items: flex-end;
        @media (max-width: 375px) {
            flex-direction: column;
        }
        &__label {
            width: 80%;
            line-height: 1.4rem !important;
            text-align: left !important;
            @media (max-width: 375px) {
                width: 100%;
            }
        }
        &__content {
            width: 100%;
            text-align: left;
        }
    }
}
.anonymousAlert {
    width: 420px;
    max-height: 250px;
    @media (max-width: 375px) {
        max-height: initial;
    }

    .el-message-box__content {
        height: auto;
    }
}
.complaint-resume {
    .col-md-4,
    .col-md-6,
    .col-md-8 {
        color: $text-color-default;
        text-align: left;
        margin-bottom: 0.5rem;
        padding: 0.5rem 0;
    }
    p {
        color: $text-color-default;
        text-align: left;
    }
    .bg-light {
        padding: 0.5rem 1rem;
    }
    .div-state-log {
        overflow: auto;
        max-height: 25vh;
    }
}
.is-active {
    margin-bottom: 1rem;
}
.switch-compact {
    .el-switch__label {
        margin: auto 0.5rem;
    }
}
.el-collapse-item {
    &__header {
        border-bottom: 0 !important;
    }
    &__content {
        padding-bottom: 5px;
    }
    &__wrap {
        border-bottom: 0;
    }
}
.div-filters {
    .el-collapse-item__content {
        padding-bottom: 0;
    }
}
.div-complaint-detail {
    .el-card__body {
        padding-bottom: 5px;
    }
    .el-tag {
        margin: 5px;
        border-radius: 0;
    }
    .tag-complaint-state {
        background-color: $logo-2;
        border-color: $logo-2;
    }
}
.btn-big-icon {
    padding: 6px 10px !important;
    max-height: 40px;
}
.big-icon {
    font-size: 1.2rem;
}

.updateDialog {
    .el-dialog {
        // width: 600px;
        @media (max-width: 375px) {
            width: 100%;
        }
    }
    .el-form .el-form-item__label {
        word-break: break-word;
        // width: 45%;
        // @media (max-width: 375px) {
        //   width: 90%;
        // }
    }
}
.div-state-logs {
    overflow-y: auto;
    max-height: 200px;
}
@media (max-width: 375px) {
    .denuncia-ahora {
        bottom: 37px !important;
    }
    .modal-data-space {
        max-width: 230px;
    }
}

@media (max-width: 767px) {
    .div-filter {
        margin-top: 1rem;
        button {
            width: 100%;
        }
    }
    .valor-etico-logo {
        height: 30px;
    }
    .el-message-box {
        max-width: 90vw;
        max-height: 80vh;
    }
}
@media (max-width: 991.98px) {
    .inicio {
        margin-bottom: 1rem;
    }
    .divoptionshome {
        margin-bottom: 10%;
    }
}

@media (min-width: 992px) {
    .divoptionshome {
        margin-bottom: 5%;
    }
}

@media (min-width: 1200px) {
    .divoptionshome {
        margin-bottom: 10%;
    }
}
h6 {
    font-size: 1.05em !important;
    letter-spacing: 0.015em !important;
}

.list-size-upload > .el-upload-list {
    width: 12rem;
}

tr .cell-complaint-type .cell {
    max-height: 15rem;
    overflow-y: auto;
}

.width-bar .el-progress-bar {
    padding-right: 60px;
    margin-right: -65px;
    .el-progress-bar__outer {
        width: 99%;
    }
}
//CLASES NUEVAS
@media screen and (max-width: 480px) {
    .sm-title-size {
        font-size: 1.2em;
    }
}

@media screen and (max-width: 480px) {
    .sm-text-size {
        font-size: 0.9em;
    }
    .el-form-item__content .boton {
        text-align: right !important;
    }
}

@media screen and (max-width: 992px) {
    .hideColumn {
        display: none;
    }
}
@media screen and (max-width: 630px) {
    .switch .el-switch__label {
        display: none !important;
    }
}

.el-table .cell {
    word-break: normal !important;
}

.el-table .cell,
.el-table--border td:first-child .cell,
.el-table--border th:first-child .cell {
    padding-left: 20px !important;
}

.item-center .cell {
    text-align: center !important;
    padding-left: 10px !important;
}

.navbar-nav .nav-link {
    padding: 0.5rem 0.5rem;
    font-size: 0.9rem;
}

.el-popover {
    word-break: normal !important;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}
