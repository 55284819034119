// Body
$body-bg: #f8fafc;
$text-color-default: #212529;
$text-color-white: #f8fafc;
$hover-opacity: 0.75;
$inactive: #b3b3b3;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$logo-1: #36a9b1;
$logo-2: #04afa7;
$employees: #36a9b1;
$complaint: #b73122;
$good-practices: #287e26;
$providers: #10315a;
$stakeholders: #7e7f7f;
$help: #b73122;
$link: #3490dc;

// width
$carousel-width: 350px;
$max-width: 300px;
